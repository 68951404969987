
import moment from 'moment'
// import "magnific-popup"
// import "./instagram"



jQuery(document).ready(function ($) {

  // MOBILE MENU
  $('.hamburger').on('click', function () {
    $(".list-wrapper").toggleClass('active')
    $(".line").toggleClass('active')
  })

  $('.nav-button').on('click', function () {
    if ($('.list-wrapper').hasClass('active')) {
      $(".list-wrapper").toggleClass('active')
      $(".line").toggleClass('active')
    }
  })

  //  // Mobile Navigation
  //  $(".mobile-menu-icon").on('click', function (e) {
  //   e.preventDefault();
  //   $("#navbar").toggleClass("active");
  // });

  // VIDEO
  const tourVideo = document.getElementById("tour-video")
  const bioVideo = document.getElementById("bio-video")
  const merchVideo = document.getElementById("shop-video")
  const fanartVideo = document.getElementById("fanart-video")

  $('.nav-button').on('mouseover', function (e) {

    if ($("#hero").hasClass("active")) {
      let target = $(this).attr("data-hover")

      $(".responsive-bg.active").removeClass("active")

      bioVideo.pause()
      tourVideo.pause()
      merchVideo.pause()
      fanartVideo.pause()

      $(target)[0].currentTime = 0
      $(target).addClass("active")
      $(target)[0].play()
    }
  })

  bioVideo.addEventListener("ended", function (e) {
    console.log(this)
    console.log(e)
    this.classList.remove("active")
  })

  tourVideo.addEventListener("ended", function (e) {
    this.classList.remove("active")
  })

  merchVideo.addEventListener("ended", function (e) {
    this.classList.remove("active")
  })

  fanartVideo.addEventListener("ended", function (e) {
    this.classList.remove("active")
  })

  // NAVIGATION
  $(".nav-button").on("click", function (e) {
    const target = $(this).attr("data-nav");
    if (target) {
      $('.nav-button').removeClass('marked');
      $(this).addClass('marked');
      $("section.active").removeClass("active");
      $(target).addClass("active");
    }
  });

  $("#logo").on("click", function (e) {
    $("section.active").removeClass("active")
    $(".list-wrapper").removeClass('active')
    $('.nav-button').removeClass('marked');
    $(".line").removeClass('active')
    $("#hero").addClass("active")
  })



  // Instagram
  $.fn.juicer = function (options) {
    const $SELECTOR = $(this);

    let opts = $.extend({
      limit: 18,
      feed_more: $('.juicer-button'),
      btn_msg: 'View More'
    }, options);

    const init_limit = opts.limit;

    opts.feed_more.on('click', function (e) {
      if (opts.limit > 0) {
        opts.limit = 0;
        $(this).text('View Less');
      } else {
        opts.limit = init_limit;
        $(this).text(opts.btn_msg);
      }

      e.preventDefault();
    });

    $.ajax({
      url: 'https://www.juicer.io/api/feeds/locketart',
      method: 'GET',
      dataType: 'json', 
      success: function (data) {
        console.log(data)
        let c = 1;
        let html = '';

        for (let item of data.posts.items) {
          if (item.unformatted_message != null) {
            var text = item.unformatted_message.replace(/<\/?[^>]+(>|$)/g, '');
          } else {
            var text = " ";
          }
          const img = item.image;

          if (img) {
            html += `
                            <div>
                                <a href="${item.full_url}" target="_blank" style="background-image: url('${img}');">
                                    <span>${text}</span>
                                </a>
                            </div>
                        `;
          } else {
            html += `
                            <div>
                                <a href="${item.full_url}" target="_blank">
                                    <span style="opacity: 1;">${text}</span>
                                </a>
                            </div>
                        `;
          }

          if (opts.limit > 0 && c++ >= opts.limit) {
            $SELECTOR.html(html);
            return false;
          }
        }

        $SELECTOR.html(html);

        if (opts.limit === 0) {
          $('#feed > div').css('display', 'block');
        }
      },
      error: function () {
        console.log('Error loading feed.');
      }
    });
  };

  $("#instagram-feed").juicer()

  // Tourdate Import
  $.ajax({
    url: 'https://rest.bandsintown.com/artists/id_6748623/events?app_id=45PRESS_doja',
    method: 'GET',
    dataType: 'json',
    error: () => {
      alert('Error fetching events!');
    },
    success: data => {
      const events = $('#tour-dates');
      let html = '';
      let n = 0
      if (data.length) {
        for (let event of data) {
          n++
          html += '<div class="event-group">';
          html += '<div class="group">';
          html += '<div class="event-date">' + moment(event.datetime).format('MMM DD').toUpperCase() + '</div>';
          html += '<div class="event-venue">' + event.venue.name.toUpperCase() + '</div>';
          html += '</div>';
          html += '<div class="event-location">' + event.venue.location.toUpperCase() + '</div>';
          html += '<div class="event-links">';
          for (let offer of event.offers) {
            html += '<a href="' + offer.url + '" target="_blank" class="link">' + offer.type.toUpperCase() + '</a>';
          }
          html += '</div>';
          html += '</div>';
        }
        events.html(html);
      } else {
        events.html('<span class="no-events">Check back soon for new shows!</span>');
      }
      if (n < 9) {
        $("#toggle-dates").hide();
      }
    }
  });

  // // Scroll
  // $('.scroll').on('click', function (e) {
  //   e.preventDefault();

  //   const href = $(this).attr('href');
  //   const targetOffset = $(href).offset().top;
  //   const animationDuration = 500;

  //   $(".navbar").toggleClass("active");
  //   $('html, body').animate({
  //     scrollTop: targetOffset
  //   },
  //     animationDuration
  //   )
  // });

  // // Dates Toggle
  // // $('#tour-dates div:lt(9)').show()

  // $('#toggle-dates').on('click', function (e) {
  //   e.preventDefault();
  //   $('#tour-dates').toggleClass('show-first-nine')
  //   if ($('#tour-dates').hasClass('show-first-nine')) {
  //     $('#toggle-dates').text('VIEW ALL DATES')
  //   } else {
  //     $('#toggle-dates').text('SHOW LESS');
  //   }
  // })

  // $('#toggle-bio').on('click', function (e) {
  //   e.preventDefault();
  //   $('#bio').toggleClass('show-less')
  //   if ($('#bio').hasClass('show-less')) {
  //     $('#toggle-bio').text('READ MORE')
  //   } else {
  //     $('#toggle-bio').text('SHOW LESS');
  //   }
  // })

  // hide 'show more dates' button
  // if ($("#tour-dates").children().length < 9) {
  //   $("#toggle-dates").hide();
  // }

  // Modal
  //  $('.mfp-inline').magnificPopup({
  //   fixedContentPos: true
  // })
  // $('.custom-close').on('click', function () {
  //   $.magnificPopup.close();
  // })

  // pop-up
  // $.magnificPopup.open({
  //   items: {
  //     src: '#nl-popup'
  //   },
  //   type: 'inline',
  //   fixedContentPos: true
  // })

});